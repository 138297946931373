.apiManagement {
	width: 1176px;
	margin: auto;
	padding-top: 40px;
	& > :first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
		& > :first-child {
			font-weight: 700;
			font-size: 32px;
			color: #1b191d;
		}
		& > :nth-child(2) {
			display: flex;
			align-items: center;
			gap: 16px;
			& > * {
				min-width: 156px;
				width: fit-content;
			}
		}
	}
}

.noKey {
	font-weight: 600;
	font-size: 20px;
	color: #6f6f6f;
	width: 500px;
	margin: auto;
	margin-top: 160px;
	text-align: center;
	& > :nth-child(2) {
		width: 276px;
		margin-top: 24px;
	}
}

.list {
	margin-top: 56px;
	margin-bottom: 56px;

	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
		margin-bottom: 16px;
	}
}
