.codeInput {
	width: 45px;
	height: 45px;
	padding: 0;
	font-size: 24px;
	text-align: center;
	margin-right: 12px;
	text-transform: uppercase;
	color: #494949;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	background: #fff;
	background-clip: padding-box;
	@media only screen and (max-width: 1200px) {
		width: 40px;
		height: 40px;
		font-size: 16px;
		margin-right: 4px;
	}
}

.codeInput:focus {
	appearance: none;
	outline: 0;
}
.phonecodeSelect {
	max-width: 130px;
	margin-right: 10px;
}

.otpModal {
	& > :first-child {
		& > :first-child {
			width: 100%;
			font-size: 16px;
			color: #6f6f6f;

			& > :nth-child(2) {
				margin-top: 16px;
				& > :first-child {
					font-weight: 600;
					color: #424243;
				}
			}
		}
	}
}

.sso {
	width: 100%;
	margin-top: 24px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.ssoContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.iconText {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}
.tosContent {
	max-width: 1000px;
	max-height: 500px;
	overflow-y: scroll;
	word-wrap: break-word;
	white-space: pre-wrap;
	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		max-height: 400px;
		padding-bottom: 32px;
	}
}

.tosModal {
	overflow: hidden;
	padding-top: 100px;
	& > :first-child {
		& > :first-child {
			display: flex;
			flex-direction: column;
			align-items: center;
			& > :first-child {
				font-size: 24px;
				color: #1b191d;
				font-weight: 600;
				width: 100%;
			}
			& > :last-child {
				width: 180px;
				margin-top: 32px;
			}
		}
	}
}

.mobileCloseTOS {
	padding: 16px;
}
