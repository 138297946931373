.refContainer {
	width: 1176px;
	margin: auto;
	padding-bottom: 40px;
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	& > :first-child {
		font-weight: 700;
		font-size: 32px;
		color: #1b191d;
		margin-bottom: 32px;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 16px;
		padding-bottom: 100px;
	}
}
