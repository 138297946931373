.pnlContainer {
	width: 1176px;
	padding-top: 48px;
	margin: auto;
	display: flex;
	flex-direction: column;
	gap: 40px;
	& > :nth-child(2) {
		width: fit-content;
	}
	& > :first-child {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 20px;
		color: #939393;
		cursor: pointer;
	}

	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 16px;
		padding-bottom: 100px;
		gap: 12px;
		& > :first-child {
			font-size: 16px;
		}
		& > :nth-child(3) {
			margin-bottom: 28px;
		}
	}
}

.title {
	font-weight: 700;
	font-size: 32px;
	color: #1b191d;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 8px;
	@media only screen and (max-width: 1200px) {
		font-size: 20px;
	}
}
