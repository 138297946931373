/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/
:root {
	--dark-color: #323a45;

	--color-fff: #ffffff;
	--color-100: #f0f0f0;
	--color-200: #eeeeee;
	--color-300: #e0e0e0;
	--color-400: #bdbdbd;
	--color-500: #555555;
	--color-600: #757575;
	--color-700: #616161;
	--color-800: #424242;
	--color-900: #212121;
	--color-000: #000000;

	--border-color: #f0f0f0;
	--card-color: #ffffff;
	--body-color: #ffffff;
	--white-color: #ffffff;
	--sidebar-color: #fcfcfc;
	--text-color: #212529;
	--svg-color: #76808f;

	--hc-font-color: #555555;
	--hc-bg-color: #f0f0f0;

	--primary-color: #5757f7;
	--secondary-color: #5757f7;

	--chart-color1: #6aab9c;
	--chart-color2: #f7b36b;
	--chart-color3: #97b0aa;
	--chart-color4: #4d6e94;
	--chart-color5: #81a1ca;

	--buy-color: #0ecb81;
	--sell-color: #f6465d;
}

[data-theme="dark"] {
	--dark-color: #323a45;

	--color-fff: #000000;
	--color-100: #212121;
	--color-200: #262727;
	--color-300: #616161;
	--color-400: #757575;
	--color-500: #8f8f8f;
	--color-600: #bdbdbd;
	--color-700: #e0e0e0;
	--color-800: #eeeeee;
	--color-900: #f0f0f0;
	--color-000: #ffffff;

	--border-color: #353535;
	--card-color: #262727;
	--body-color: #1e1f20;
	--white-color: #ffffff;
	--sidebar-color: #fcfcfc;
	--text-color: #8f8f8f;

	--hc-font-color: #555555;
	--hc-bg-color: #f0f0f0;

	[class="theme-cyan"] {
		--primary-color: #00bdaa;
	}

	[class="theme-indigo"] {
		--border-color: #212020;
		--card-color: #101010;
		--body-color: #050505;
	}

	[class="theme-blue"] {
		--primary-color: #7258db;
		--border-color: #1a1f2d;
		--card-color: #10141f;
		--body-color: #080b13;
	}

	[class="theme-orange"] {
		--border-color: #092940;
		--card-color: #052133;
		--body-color: #051c2c;
	}

	[class="theme-blush"] {
		--border-color: #242331;
		--card-color: #1a1925;
		--body-color: #15141f;
	}

	[class="theme-red"] {
		--primary-color: #f73e60;
		--border-color: #222335;
		--card-color: #161725;
		--body-color: #11121d;
	}
}

[class="theme-light"] {
	--primary-color: #5757f7;
	--secondary-color: #5757f7;

	--primary-rgb: 254, 176, 25;

	--chart-color1: #ef7e56;
	--chart-color2: #44558f;
	--chart-color3: #ded5c4;
	--chart-color4: #f59292;
	--chart-color5: #90a4ae;

	--primary-gradient: linear-gradient(
		45deg,
		var(--primary-color),
		var(--chart-color4)
	);
	--primary-rgb: 254, 176, 25;
}

.secondary-color {
	color: var(--secondary-color);
}
.secondary-color-fill {
	fill: var(--secondary-color);
}
