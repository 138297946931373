.createAPIModal {
	width: 440px;
	& > :first-child {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}
}

.createContent {
	& > :nth-child(1) {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
		margin-bottom: 32px;
	}
	& > :nth-child(2) {
		margin-bottom: 24px;
		font-weight: 400;
		font-size: 16px;
		color: #424243;
	}
	& > :nth-child(3) {
		margin-bottom: 48px;
	}
	& > :nth-child(4) {
		width: fit-content;
		min-width: 92px;
		margin: auto;
	}
}
.otp {
	& > :first-child {
		font-weight: 600;
		font-size: 16px;
		color: #424243;
		margin-bottom: 8px;
	}
}

.keyItem {
	padding: 32px 24px;
	& > :first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > :first-child {
			font-weight: 600;
			font-size: 24px;
			color: #1b191d;
		}
		& > :nth-child(2) {
			display: flex;
			gap: 24px;
			font-weight: 600;
			font-size: 16px;
			color: #5757f7;
			& > * {
				cursor: pointer;
			}
			& > :nth-child(2) {
				color: #939393;
			}
		}
	}
}
.keyDetail {
	margin-top: 20px;
	display: flex;
	gap: 80px;
	& > * {
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > :first-child {
			font-weight: 600;
			font-size: 16px;
			color: #424243;
		}
	}
}

.keyLimit {
	display: flex;
	align-items: center;
	gap: 48px;
	& > * {
		display: flex;
		align-items: center;
		gap: 8px;
		& > :first-child {
			accent-color: #5757f7;
		}
	}
}

.keyList {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.createdKeyData {
	& > * {
		word-break: break-all;
		max-width: 100%;
	}
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
		margin-bottom: 24px;
	}

	& > :nth-child(2) {
		font-weight: 600;
		font-size: 20px;
		color: #424243;
		margin-bottom: 16px;
	}
	& > :nth-child(3) {
		display: flex;
		flex-direction: column;
		gap: 12px;
		& > * {
			display: flex;
			flex-direction: column;
			gap: 8px;
			& > :first-child {
				font-weight: 600;
				font-size: 16px;
				color: #424243;
			}
			& > :nth-child(2) {
				& > :nth-child(2) {
					accent-color: #5757f7;
				}
			}
		}
	}
	& > :nth-child(4) {
		width: 92px;
		margin: auto;
		margin-top: 32px;
	}
}
