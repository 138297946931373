.signin {
	width: 100vw;
	height: 100vh;
	background-color: #a0f3f9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}
.anBg {
	width: 1200px;
	min-width: 600px;
	height: 800px;
	min-height: 400px;
	background-image: url(../../assets//images/bg/empty-bg.png);
	background-repeat: no-repeat;
	background-size: auto 100%;
	overflow: show;
	// background-color: red;
}
.background {
	width: 100%;
	height: 100%;
	flex: 1;
	background-image: url(../../assets//images/bg/sign-in-bg.jpg);
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center;
}

.signinNoAn {
	width: 100vw;
	height: 100vh;
	background-image: url(../../assets//images/bg/sign-in-bg.jpg);
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-color: #a0f3f9;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	overflow: hidden;
}

.content {
	height: 100%;
	width: 536px;
	background-color: #fff;
	padding: 64px 48px;
	overflow-y: auto;
	overflow-x: hidden;
}

.backhome {
	font-weight: 600;
	font-size: 20px;
	color: rgb(93, 93, 93) !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 12px;
}

.title {
	margin-top: 28px;
	font-weight: 700;
	font-size: 32px;
	// margin-bottom: 12px;
}

.welcome {
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 24px;
}

.signinForm {
	padding: 32px;
	width: 440px;
	max-width: 100%;
	@media only screen and (max-width: 1200px) {
		padding: 0px;
	}
}

.label {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
	margin-bottom: 8px;
}

.placeholder {
	font-weight: 400;
	font-size: 14px;
	color: #939393;
}

.inputContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	padding: 8px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.customInput {
	outline: none;
	padding: 0px;
	margin: 0px;
	border: none;
	width: 100%;
	font-size: 14px;
}

.customInput:focus {
	outline: none;
}

.customInput::placeholder {
	color: rgba(147, 147, 147, 1);
}

input:autofill {
	background-color: #fff !important;
}

input:-webkit-autofill {
	background-color: #fff !important;
}

.btnContentDark {
	font-weight: 600;
	font-size: 16px;
	color: #fdfcff;
}

.btnContentLight {
	font-weight: 600;
	font-size: 16px;
	color: #939393;
}

.forgotPassword {
	margin: auto;
	margin-top: 12px;
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #939393;
	text-align: center;
	cursor: pointer;
	width: fit-content;
}

.dontHaveAccount {
	font-size: 16px;

	color: #424243;
}
.btnLightContainer {
	width: 176px;
}

.animationElement {
	width: 200px;
}

.animateBg {
	position: relative;
	width: 100%;
	height: 100%;
}

.mobileSignin {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	padding: 48px 16px 100px 16px;
	& > * {
		width: 100%;
	}
	& > :first-child {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 600;
		font-size: 16px;
		color: #939393;
		margin-bottom: 26px;
	}
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
		margin-bottom: 8px;
	}
	& > :nth-child(3) {
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 32px;
	}
}
