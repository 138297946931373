@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #f3f3f3;
	border-top: 5px solid #383636;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
	position: relative;
	z-index: 9;
}
.spinner-container {
	display: grid;
	justify-content: center;
	align-items: center;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		background: #00000038;
		height: 100%;
	}
}

.popupUserEmailConfirm {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99999;
	.shadowPopupEmail {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #00000038;
		top: 0;
		left: 0;
		cursor: pointer;
	}
	.popupUserEmailConfirm__content {
		width: 400px;
		height: 300px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background-color: #fff;
		border-radius: 5px;
		padding: 50px 20px 20px 20px;
		text-align: center;
		.img {
			float: left;
			width: 100%;
			text-align: center;
		}
		p {
			float: left;
			width: 100%;
			margin: 20px 0;
		}
		.btn-group {
			display: inline-block;
			max-width: 250px;
			margin-top: 50px;
		}
	}
}
