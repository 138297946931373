.inviteFriends {
	width: 1176px;
	background-color: #e6e6fe;
	border: 1px solid #5757f7;
	border-radius: 20px;
	height: 344px;
	margin-top: 32px;
	overflow: hidden;
	display: flex;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #fdfdfe;
		padding-left: 42px;
		padding-top: 118px;
		width: 784px;
		background: url("../../assets/images/bg/ref.svg");
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		border-right: 1px solid #5757f7;

		& > * {
			width: 426px;
			@media only screen and (max-width: 1200px) {
				width: 200px;
			}
		}
		@media only screen and (max-width: 1200px) {
			width: 100%;
			padding: 16px;
			font-size: 16px;
			height: 344px;
			padding-top: 180px;
			background-position: right center;
		}
	}
	& > :nth-child(2) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 24px;
		padding: 60px;
		width: 392px;
		& > * {
			width: 100%;
		}
		@media only screen and (max-width: 1200px) {
			width: 100%;
			padding: 16px;
			gap: 8px;
			padding-bottom: 24px;
		}
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		flex-direction: column;
		height: fit-content;
	}
}

.refID {
	width: 276px;
	& > :first-child {
		font-weight: 600;
		font-size: 16px;
		color: #424243;
		margin-bottom: 8px;
	}
	& > :nth-child(2) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #dedde0;
		border: 1px solid #1c1a1f;
		border-radius: 12px;
		height: 40px;
		padding: 10px 12px;
	}
}

.refLink {
	width: 276px;
	& > :first-child {
		font-weight: 600;
		font-size: 16px;
		color: #424243;
		margin-bottom: 8px;
	}
	& > :nth-child(2) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #dedde0;
		border: 1px solid #1c1a1f;
		border-radius: 12px;
		height: 40px;
		padding: 10px 12px;
	}
	padding-bottom: 24px;
}

.instruction {
	margin-top: 56px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
	& > :first-child {
		& > * {
			width: 1176px;
			@media only screen and (max-width: 1200px) {
				width: 100%;
			}
		}
		& > :nth-child(2) {
			padding: 24px 28px 45px 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			& > :first-child {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 30px;
				@media only screen and (max-width: 1200px) {
					flex-direction: column;
				}
				& > * {
					width: 352px;
					height: 352px;
					border-radius: 20px;
					background-color: #e6e6fe;
					border: 1px solid #5757f7;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-direction: column;
					padding: 24px;
					& > :first-child {
						width: 48px;
						height: 48px;
						background: #fdfcff;
						font-weight: 700;
						font-size: 32px;
						color: #5757f7;
						border-radius: 50%;
						text-align: center;
					}
					& > :nth-child(3) {
						color: #4646c5;
						font-weight: 600;
						font-size: 20px;
					}
					@media only screen and (max-width: 1200px) {
						width: 100%;
					}
				}
			}
			& > :nth-child(2) {
				margin-top: 40px;
				width: 276px;
				@media only screen and (max-width: 1200px) {
					width: 100%;
				}
			}
			@media only screen and (max-width: 1200px) {
				padding: 16px;
			}
		}
	}
}

.refModal {
	& > :first-child {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}
	& > :nth-child(2) {
		border-radius: 10px;
		margin-top: 4px;
		margin-bottom: 16px;
	}
	& > :nth-child(3) {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;
		& > * {
			border-radius: 50%;
			border: 1px solid #1c1a1f;
			box-shadow: 2px 2px 0px #100624;
		}
	}
}

.refModalMobile {
	& > :nth-child(1) {
		border-radius: 10px;
		margin-bottom: 16px;
	}
	& > :nth-child(2) {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;
		margin-bottom: 32px;

		& > * {
			border-radius: 50%;
			border: 1px solid #1c1a1f;
			box-shadow: 2px 2px 0px #100624;
		}
	}
}

.instructionModal {
	& > :first-child {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}
	& > :nth-child(2) {
		width: 596px;
	}
	& > :nth-child(3) {
		margin: auto;
		width: 128px;
	}
}

.instTitle {
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
}

.rulesMobile {
	padding-bottom: 32px;
}

.achievements {
	margin-top: 40px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
	& > :first-child {
		width: 1176px;
		@media only screen and (max-width: 1200px) {
			width: 100%;
		}
		& > :nth-child(2) {
			padding: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			& > * {
				width: 352px;
				height: 112px;
				padding: 16px 12px;
				font-weight: 700;
				font-size: 32px;
				color: #424243;
				& > :first-child {
					font-weight: 400;
					font-size: 16px;
					color: #939393;
				}
				@media only screen and (max-width: 1200px) {
					width: 100%;
				}
			}

			@media only screen and (max-width: 1200px) {
				flex-direction: column;
				gap: 16px;
				width: 100%;
			}
		}
	}
}

.reward {
	margin-top: 56px;
	margin-bottom: 56px;
	& > :first-child {
		width: 1176px;
		@media only screen and (max-width: 1200px) {
			width: 100%;
		}
		& > :nth-child(2) {
			padding: 24px 28px;
		}
	}
}

.noReward {
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 40px;
	font-weight: 400;
	font-size: 20px;
	color: #424243;
	& > :nth-child(2) {
		width: 276px;
	}
}
