.reset {
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 1200px) {
		width: 100vw;
	}
}
.container {
	margin-top: 64px;

	@media only screen and (max-width: 1200px) {
		padding: 100px 16px;
		margin-top: 0px;
		width: 100%;
	}
}

.title {
	font-weight: 700;
	font-size: 32px;
	margin-bottom: 24px;
	@media only screen and (max-width: 1200px) {
		font-weight: 600;
		font-size: 24px;
	}
}

.content {
	padding: 32px;
	width: 440px;
	@media only screen and (max-width: 1200px) {
		padding: 0px;
		width: 100%;
	}
}

.inputContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	padding: 8px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.customInput {
	outline: none;
	padding: 0px;
	margin: 0px;
	border: none;
	width: 100%;
	font-size: 14px;
}

.customInput:focus {
	outline: none;
}

.label {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
	margin-bottom: 8px;
}

.passwordDetailContainer {
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.passwordInvalid {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: rgba(221, 76, 86, 1);
}

.passwordValid {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: rgba(46, 189, 86, 1);
}

.passwordDetail {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: #939393;
}
