.navBtnContainer {
	display: flex;
	gap: 24px;

	@media only screen and (max-width: 1200px) {
		width: 100%;
		justify-content: space-around;
	}
}

.contentHead {
	width: 100%;
	padding: 14px 28px;
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
	border-bottom: 1px solid #1c1a1f;
	& > :first-child {
		width: fit-content;
	}
	@media only screen and (max-width: 1200px) {
		padding: 12px;
	}
}

.headText {
	display: flex;
	gap: 8px;
	align-items: center;
	width: fit-content;
}

.contentBody {
	padding: 20px 28px;
	@media only screen and (max-width: 1200px) {
		padding: 20px 12px;
	}
}

.walletDetail {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
	width: 100%;
	display: flex;
	align-items: center;
	& > :first-child {
		& > :first-child {
			& > :nth-child(2) {
				font-weight: 600;
				font-size: 32px;
				color: #1b191d;
			}
			margin-bottom: 24px;
		}

		& > :nth-child(2) {
			& > :nth-child(2) {
				font-weight: 400;
				font-size: 32px;
				color: #939393;
			}
		}
	}
	@media only screen and (max-width: 1200px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		& > :first-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			& > :first-child {
				& > :nth-child(2) {
					font-size: 20px;
				}
				margin-bottom: 0px;
			}

			& > :nth-child(2) {
				& > :nth-child(2) {
					font-size: 20px;
				}
			}
		}
	}
}

.chartContainer {
	flex: 1;
	width: 100%;
	// background-color: aqua;
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0px 60px 0px 100px;
	@media only screen and (max-width: 1200px) {
		margin: 0px;
	}
}

.pnlChartContainer {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column-reverse;
	gap: 16px;
	@media only screen and (max-width: 1200px) {
		gap: 8px;
	}
}

.overviewContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.chartLegendOverview {
	color: #6f6f6f;
	font-weight: 400;
	font-size: 20px;
	& > :first-child {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 24px;
		color: #424243;
		& > :first-child {
			border-radius: 50%;
		}
	}
	@media only screen and (max-width: 1200px) {
		& > :first-child {
			font-size: 16px;
			color: #424243;
			& > :first-child {
				border-radius: 50%;
			}
		}
		font-size: 14px;
	}
}
.chartLegendGrid {
	display: grid;
	gap: 30px;
	width: 200px;
	grid-template-columns: auto auto;
	margin-right: 150px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		margin: 0px;
	}
}
.chartLegendGridItem {
	color: #6f6f6f;
	font-weight: 400;
	font-size: 20px;

	& > :first-child {
		display: flex;
		align-items: center;
		gap: 8px;
		& > :first-child {
			border-radius: 50%;
		}
	}

	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
}
.walletTitle {
	display: flex;
	align-items: center;
	gap: 18px;
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
	margin-bottom: 20px;
	& > :first-child {
		cursor: pointer;
	}

	@media only screen and (max-width: 1200px) {
		font-size: 20px;
	}
}

.walletFormContainer {
	width: 440px;
	padding: 32px 32px;
	@media only screen and (max-width: 1200px) {
		padding: 20px 12px;
		width: 100%;
	}
}

.depositContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	& > :nth-child(2) {
		margin-top: 24px;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.depositDes {
	color: #6f6f6f;
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 20px;
}

.depositForm {
	display: flex;
	flex-direction: column;
	gap: 12px;
	& > * {
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > :first-child {
			font-weight: 600;
			font-size: 16px;
			color: #424243;
			display: flex;
			gap: 8px;
		}
		& > :nth-child(2) {
			margin: 0px;
		}
		& > :nth-child(3) {
			display: flex;
			justify-content: space-between;
			& > :first-child {
				font-weight: 400;
				font-size: 14px;
				color: #6f6f6f;
			}
			& > :nth-child(2) {
				font-weight: 600;
				font-size: 14px;
				color: #6f6f6f;
			}
		}
	}
}

.chooseNetworkHead {
	display: flex;
	align-items: center;
	gap: 8px;
}

.depositCheckout {
	border-top: 1px solid #1c1a1f;
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 20px;
	& > :first-child {
		& > * {
			display: flex;
			align-items: center;
			justify-content: space-between;
			& > :first-child {
				font-weight: 400;
				font-size: 16px;
				color: #6f6f6f;
			}
			& > :last-child {
				font-weight: 600;
				font-size: 16px;
				color: #424243;
			}
		}
	}
}

.customSelect {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	font-weight: 400;
	font-size: 14px;
	color: #939393;
	height: 40px;
	&:focus {
		border: 1px solid #5757f7;
	}
}
.depositAddressContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 20px;
	& > :nth-child(2) {
		word-break: break-word;
		overflow-wrap: break-word;
		display: flex;
		flex-direction: column;
		align-items: center;
		& > :first-child {
			font-weight: 400;
			font-size: 15px;
			color: #1b191d;
		}
		& > :last-child {
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 4px;
			font-weight: 400;
			font-size: 12px;
			color: #6f6f6f;
			margin-top: 16px;
			& > :last-child {
				width: 180px;
			}
		}
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		& > :nth-child(2) {
			& > :last-child {
				justify-content: flex-start;
				& > :last-child {
					width: fit-content;
				}
			}
		}
	}
}
.copyBtn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	width: 82px;
	height: 28px;
	background: #fefefe;
	border: 1px solid #1c1a1f;
	border-radius: 14px;
	font-weight: 400;
	font-size: 14px;
	color: #1b191d;
}

.copyBtn:active {
	opacity: 0.8;
}

.walletTabs {
	& > * {
		& > :first-child {
			margin-right: 20px;
		}
	}
}

.customQuantityContainer {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	gap: 12px;
	padding-left: 12px;
	& > :nth-child(2) {
		width: 72px;
		background: #5757f7;
		font-weight: 400;
		font-size: 14px;
		color: #ffffff;
		border: 1px solid #1c1a1f;
		border-radius: 0px 12px 12px 0px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			opacity: 0.9;
		}
	}
}

.caution {
	font-weight: 400;
	font-size: 16px;
	color: #dd4c56;
}

.transferForm {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	& > * {
		width: 100%;
	}
	margin-bottom: 24px;
}

.transferElement {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	@media only screen and (max-width: 1200px) {
		gap: 4px;
	}
	& > * {
		display: flex;
		flex-direction: column;
	}
	& > :not(:nth-child(2)) {
		width: 168px;
	}
	& > :nth-child(2) {
		margin-top: 32px;
		width: fit-content;
	}
}

.formLabel {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
}

.formDes {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	& > :first-child {
		font-weight: 400;
		font-size: 14px;
		color: #6f6f6f;
	}
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 14px;
		color: #6f6f6f;
	}
}

.pnlOverview {
	padding-top: 16px;
	display: flex;
	align-items: center;
	gap: 20px;
	font-size: 20px;
	& > * {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	& > :first-child {
		font-weight: 400;

		color: #6f6f6f;
		& > :nth-child(2) {
			font-weight: 600;
		}
	}

	& > :nth-child(2) {
		line-height: 28px;
		color: #5757f7;
		cursor: pointer;
		font-weight: 400 !important;
	}

	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
}

.pnlSelect {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 24px;
	& > * {
		padding: 8px 24px;
		height: 40px;
		max-width: 185px;
		border-radius: 20px;
		border: 1px solid #939393;
		color: #939393;
		cursor: pointer;
	}
}

.pnlActive {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.85),
			rgba(255, 255, 255, 0.85)
		),
		#5858fa;
	color: #5757f7;
	border: 1px solid #5757f7;
}

.listItem {
	padding: 16px 12px;
	border-bottom: 1px solid #dedde0;
	&:hover {
		background: #e6e6fe;
	}
	& > :first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > :first-child {
			display: flex;
			align-items: center;
			gap: 8px;
			& > :first-child {
				font-weight: 600;
				font-size: 16px;
				color: #1b191d;
			}
		}
	}
	& > :nth-child(2) {
		margin-top: 4px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		& > * {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: 400;
			font-size: 12px;
			color: #424243;
			& > :first-child {
				color: #939393;
			}
		}
	}
}
