.walletConatainer {
	padding-top: 48px;
	padding-bottom: 48px;

	display: flex;
	flex-direction: column;
	gap: 40px;
	margin: auto;
	align-items: center;
	& > :first-child {
		display: flex;
		justify-content: space-between;
	}
	@media only screen and (max-width: 1200px) {
		margin: 0;
		padding: 48px 16px;

		& > :first-child {
			flex-direction: column;
			gap: 20px;
			width: 100%;
		}
	}
}
.walletTabs {
	justify-content: flex-start;
	& > * {
		width: fit-content;
		& > :first-child {
			width: fit-content;
		}
	}
}
