.signup {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	// overflow-x: hidden;
}
.background {
	width: 100%;
	height: 100%;
	flex: 1;
	background-color: #251e3a;
	background-image: url(../../assets/images/bg/new_sign_up.svg);
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center;
}

.signupSuccess {
	width: 100vw;
	height: 100vh;
	background-image: url(../../assets//images/bg/sign-up-success-bg.png);
	background-repeat: no-repeat;
	background-size: 100% auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 1200px) {
		padding: 16px;
		background-image: url(../../assets/images/bg/sign-up-success-bg.jpg);
		background-repeat: no-repeat;
		background-size: auto;
	}
}

.content {
	height: 100%;
	background-color: #fff;
	padding: 64px 48px;
	overflow-y: scroll;
}

.backhome {
	font-weight: 600;
	font-size: 20px;
	color: rgb(93, 93, 93) !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
}

.title {
	margin-top: 28px;
	font-weight: 700;
	font-size: 32px;

	// margin-bottom: 12px;
}

.welcome {
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 24px;
}

.signinForm {
	padding: 32px;
	width: 440px;
	max-width: 100%;
	@media only screen and (max-width: 1200px) {
		padding: 0px;
	}
}

.label {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
	margin-bottom: 8px;
}

.placeholder {
	font-weight: 400;
	font-size: 14px;
	color: #939393;
}

.inputContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	padding: 8px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.inputContainerErr {
	border: 1px solid rgba(221, 76, 86, 1);
	border-radius: 12px;
	padding: 8px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.customInput {
	outline: none;
	padding: 0px;
	margin: 0px;
	border: none;
	width: 100%;
	font-size: 14px;
}

.customInput:focus {
	outline: none;
}

.customInput::placeholder {
	color: rgba(147, 147, 147, 1);
}

.customInput:autofill {
	background-color: #fff !important;
}

.customInput:-webkit-autofill {
	background-color: #fff !important;
}

.btnContentDark {
	font-weight: 600;
	font-size: 16px;
	color: #fdfcff;
}

.btnContentLight {
	font-weight: 600;
	font-size: 16px;
	color: #939393;
}

.forgotPassword {
	margin-top: 12px;
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #939393;
	text-align: center;
}

.dontHaveAccount {
	font-size: 16px;

	color: #424243;
}
.btnLightContainer {
	width: 176px;
}

.animationElement {
	width: 200px;
}

.inputPhoneContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	padding: 0px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.inputPhoneContainerErr {
	border: 1px solid rgba(221, 76, 86, 1);
	border-radius: 12px;
	padding: 0px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.countrySelect {
	padding: 8px 0px;
	border: none;
	border-right: 1px solid #000;
	margin-right: 8px;
	color: rgba(147, 147, 147, 1);
	font-size: 14px;
}

.countrySelect:focus {
	outline: none;
}

.passwordDetail {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: #939393;
}

.passwordInvalid {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: rgba(221, 76, 86, 1);
}

.passwordValid {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	color: rgba(46, 189, 86, 1);
}

.passwordDetailContainer {
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.bottomLine {
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.successTitle {
	text-align: center;
	margin-top: 176px;
	font-weight: 700;
	font-size: 40px;
	color: #1b191d;
	margin-bottom: 30px;
	@media only screen and (max-width: 1200px) {
		margin-top: 150px;
		font-weight: 600;
		font-size: 24px;
	}
}
.successContent {
	font-size: 16px;
	color: #424243;
	text-align: center;
}

.successBtnContainer {
	width: 280px;
	margin-top: 53px;
}

.mobileSignup {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	padding: 48px 16px 100px 16px;
	overflow-y: scroll;
	& > * {
		width: 100%;
	}
	& > :first-child {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 600;
		font-size: 16px;
		color: #939393;
		margin-bottom: 26px;
	}
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
		margin-bottom: 8px;
	}
	& > :nth-child(3) {
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 32px;
	}
}

.tosContainer {
	display: flex;
	gap: 8px;
	margin-bottom: 12px;
	width: 100%;
	& > :nth-child(2) {
		& > :nth-child(2) {
			text-decoration: underline;
			color: #5757f7;
			cursor: pointer;
		}
	}
}
