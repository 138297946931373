.account {
	margin: auto;
	width: 1176px;
	margin-top: 48px;
	margin-bottom: 48px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 16px;
	}
}

.title {
	color: #1b191d;
	font-weight: 700;
	font-size: 32px;
}

.display {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 56px;
}

.content {
	width: 1176px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.contentTitle {
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
	padding: 14px 28px;
	margin-bottom: 0px;
	border-bottom: 1px solid #1b191d;
}

.contentDetail {
	padding: 32px 64px;
	@media only screen and (max-width: 1200px) {
		padding: 20px 12px;
	}
}
.infoContainer {
	display: flex;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
}
.personalInfo {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-left: 26.5px;
	& > * {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
}
.customBtnContainer {
	width: 90px;
}

.customSmallBtnContainer {
	width: 65px;
}

.personalInfoTitle {
	margin-left: 8px;
	font-weight: 600;
	font-size: 16px;
	color: #6f6f6f;
}

.changePassword {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding-bottom: 32px;
	border-bottom: 1px solid #1c1a1f;
	margin-bottom: 32px;
}

.changePasswordTitle {
	font-weight: 600;
	font-size: 20px;
	color: #424243;
	margin-left: 8px;
}

.twofaDetail {
	margin-top: 48px;
	padding-left: 32px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 56px;
	margin-bottom: 32px;
	@media only screen and (max-width: 1200px) {
		margin: 0;
		padding: 0px;
		gap: 16px;
		margin-top: 16px;
	}
}

.twofaDetailItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	& > :nth-child(2) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 46.5%;
	}
	@media only screen and (max-width: 1200px) {
		border-top: 1px solid #dedde0;
		padding-top: 16px;
		flex-direction: column;
		gap: 8px;
		& > :nth-child(2) {
			width: 100%;
		}
		& > :first-child {
			width: 100%;
		}
	}
}
.twofaTitle {
	font-weight: 600;
	font-size: 16px;
	color: #1b191d;
	margin-left: 8px;
}
.twofaDescription {
	font-style: italic;
	font-weight: 400;
	font-size: 14px;
	color: #6f6f6f;
	margin-top: 8px;
}

.verified {
	font-weight: 600;
	font-size: 16px;
	color: #2ebd56;
	margin-left: 8px;
}
.unverified {
	font-weight: 600;
	font-size: 16px;
	color: #dd4c56;
	margin-left: 8px;
}

.pending {
	font-weight: 600;
	font-size: 16px;
	color: #2563eb;
	margin-left: 8px;
}
.itemFixed {
	width: 300px;
	display: flex;
	flex-direction: column;
}

.verifyStatus {
	width: 150px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.lastCol {
	direction: rtl;
	width: 92px;
}

.kycCol {
	display: flex;
	align-items: center;
	width: 332px;
}

.modalBottom {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	gap: 24px;
	margin-top: 32px;
	@media only screen and (max-width: 1200px) {
		margin-bottom: 32px;
		& > * {
			width: 50%;
		}
	}
}

.selectedImgContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 20px;
}

.selectedImgItem {
	width: 50%;
	font-weight: 400;
	font-size: 14px;
	color: #6f6f6f;
}

.ggAuthStepContainer {
	font-weight: 400;
	font-size: 16px;
	white-space: pre-wrap;
}

.scanqr {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 24px;
}

.kycModalContainer {
	width: 584px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.twoFAModalContainer {
	width: 584px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding-bottom: 32px;
	}
}

.qrContainner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
	}
}

.checkContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 24px;
	margin-bottom: 16px;
}

.caution {
	font-style: italic;
	font-weight: 400;
	font-size: 14px;
	color: #dd4c56;
}

.kycImageContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		& > * {
			width: 100% !important;
			min-height: 180px;
		}
	}
}

.kycContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	& > :nth-child(2) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 45.25%;
		@media only screen and (max-width: 1200px) {
			width: 100%;
		}
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		gap: 8px;
	}
}
