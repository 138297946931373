.luckyWheelContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	& > :first-child {
		position: relative;
		margin-top: 100px;
	}
}

.luckyWheel {
	width: 500px;
	height: 500px;
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	background: gray;
	@for $i from 1 through 12 {
		& > :nth-child(#{$i}) {
			// multiply by 10 for larger hue increments
			background: hsl($i * 30, 50%, 50%);
			transform: rotate((($i * 30deg)-30deg));
			top: calc(250px - calc(250px * sin(16deg)));
			left: 250px;
			clip-path: polygon(100% 0, 0 50%, 100% 100%);
		}
	}
	// transition: all 10s cubic-bezier(0.2, 0, 0.15, 1);
}

.prizeLabel {
	width: 250px;
	height: calc(250px * 2 * sin(16deg));

	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	transform-origin: left center;
	padding-right: 50px;
	margin: 0;
}

.spinButton {
	width: 100px;
	height: 100px;
	background: white;
	border-radius: 50%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 250px;
	left: 250px;
	transform: translate(-50%, -50%);
	cursor: pointer;
	border: none;
	&:disabled {
		cursor: not-allowed;
	}
}
.arrow {
	background: black;
	width: 50px;
	height: 20px;
	clip-path: polygon(100% 0, 0 50%, 100% 100%);
	position: absolute;
	right: 0;
	top: 250px;
	transform: translate(40px, -50%);
}

.powerBar {
	margin-top: 20px;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	background-color: gray;
	transition: all 0.1s ease;
	overflow: hidden;

	& > :first-child {
		border-radius: 5px;
		width: 0%;
		height: 10px;
		background-color: red;
	}
}
