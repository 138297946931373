.pnlOverview {
	padding: 32px 48px;
	display: flex;
	justify-content: space-between;
	& > * {
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > :first-child {
			font-weight: 600;
			font-size: 16px;
			color: #424243;
		}
	}
	& > :first-child {
		& > :nth-child(2) {
			font-weight: 600;
			font-size: 32px;
			color: #1b191d;
			@media only screen and (max-width: 1200px) {
				font-size: 20px;
			}
		}
	}

	& > :nth-child(2) {
		& > :nth-child(2) {
			font-weight: 400;
			font-size: 32px;
			color: #939393;
			@media only screen and (max-width: 1200px) {
				font-size: 20px;
			}
		}
	}

	& > :nth-child(3),
	:nth-child(4) {
		& > :nth-child(2) {
			font-weight: 700;
			font-size: 24px;
			@media only screen and (max-width: 1200px) {
				font-size: 16px;
				display: flex;
				flex-direction: column;
			}
			& > :nth-child(2) {
				font-weight: 400;
			}
		}
	}
	@media only screen and (max-width: 1200px) {
		padding: 16px;
		display: grid;
		grid-template-columns: auto auto;
		gap: 16px;
	}
}

.chartsContainer {
	width: 100%;
	@media only screen and (max-width: 1200px) {
		& > :nth-child(2) {
			flex-direction: column;
		}
	}
}

.rangeSelect {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 24px;
	& > * {
		padding: 8px 16px;
		height: 40px;
		background: #fefefe;
		border: 1px solid #1c1a1f;
		border-radius: 20px;
		cursor: pointer;
	}
	@media only screen and (max-width: 1200px) {
		margin-bottom: 16px;
	}
}

.selected {
	background: #424243;
	color: #fdfcff;
}

.chartItem {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 40px;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #424243;
	}
	& > :nth-child(2) {
		width: 720px;
		height: 472px;
		background: #fdfdfe;
		border: 1px solid #1c1a1f;
		border-radius: 10px;
		padding: 20px 20px 20px 10px;
		color: #1b191d;
		font-weight: 400;
		font-size: 16px;
		@media only screen and (max-width: 1200px) {
			width: 100%;
			height: 320px;
			max-height: fit-content;
		}
	}
}

.chartHead {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.assetsList {
	position: sticky;
	right: 0px;
	top: 120px;
	height: fit-content;
	width: 376px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #424243;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		position: relative;
		right: 0px;
		top: 0px;
	}
}

.assetsTableContainer {
	margin-top: 24px;
	border: 1px solid #1c1a1f;
	border-radius: 10px;
	width: 376px;
	margin-bottom: 40px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.actionBtn {
	padding: 8px 12px;
	min-width: 100px;
	height: 36px;
	background: #5757f7;
	border-radius: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 14px;
	color: #fdfcff;
	border: none;
	&:hover {
		opacity: 0.85;
	}
	&:active {
		opacity: 0.7;
	}
}

.pnlModal {
	width: calc(1176px - 64px);
	& > :first-child {
		position: absolute;
		top: 24px;
		right: 24px;
		cursor: pointer;
	}
}

.modalContent {
	display: flex;
	flex-direction: column;
	gap: 24px;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
	}
}

.pnlDetail {
	display: flex;
	justify-content: space-between;
	& > :first-child {
		display: flex;
		gap: 40px;

		& > * {
			display: flex;
			flex-direction: column;
			gap: 8px;
			& > :first-child {
				font-weight: 600;
				font-size: 16px;
				color: #424243;
			}
		}
		& > :first-child {
			& > :nth-child(2) {
				font-weight: 600;
				font-size: 32px;
				color: #1b191d;
				@media only screen and (max-width: 1200px) {
					font-size: 20px;
				}
			}
		}

		& > :nth-child(2) {
			& > :nth-child(2) {
				font-weight: 400;
				font-size: 32px;
				color: #939393;
				@media only screen and (max-width: 1200px) {
					font-size: 20px;
				}
			}
		}

		& > :nth-child(3),
		:nth-child(4) {
			& > :nth-child(2) {
				font-weight: 700;
				font-size: 24px;
				@media only screen and (max-width: 1200px) {
					font-size: 16px;
				}
				& > :nth-child(2) {
					font-weight: 400;
				}
			}
		}
	}
	& > :nth-child(2) {
		width: 144px;
	}

	@media only screen and (max-width: 1200px) {
		width: 100%;
		margin-bottom: 16px;
		& > :first-child {
			width: 100%;
			display: grid;
			grid-template-columns: auto auto;
			gap: 16px;
		}
	}
}

.detailCharts {
	display: flex;
	gap: 40px;
	& > * {
		width: 50%;
		& > :first-child {
			font-weight: 600;
			font-size: 24px;
			color: #424243;
			margin-bottom: 16px;
		}
		& > :nth-child(2) {
			border: 1px solid #1c1a1f;
			border-radius: 10px;
			padding: 16px;
			height: 336px;
		}
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		& > * {
			width: 100%;
		}
		margin-bottom: 32px;
	}
}

.mobileTradeBtn {
	position: sticky;
	bottom: 0px;
	right: 0px;
	background: #fff;
	padding-bottom: 16px;
	padding-top: 16px;
}
