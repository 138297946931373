.headerContainer {
	max-width: 100vw;
	overflow: hidden;
}
.emailText {
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 600;
}

.flag {
	display: flex;
	align-items: center;
	gap: 8px;
}

.btnLight {
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 30px;
	box-shadow: #000 4px 4px 0 0;
	color: #000;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.btnLight:active {
	box-shadow: #000 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.contentContainer {
	box-sizing: border-box;
	gap: 10px;
	background: #ffffff;
	border-radius: 20px;
	border: 1px solid rgba(3, 1, 6, 0.9);
	box-shadow: 7px 7px 0px rgba(16, 6, 36, 0.7),
		0px 0px 2px rgba(16, 6, 36, 0.7);
	width: fit-content;
	height: fit-content;
}

.pairName {
	align-items: center;
	// justify-content: space-between;
	display: flex;
	width: 100%;
	gap: 8px;
	cursor: pointer;

	& > :nth-child(1) {
		position: relative;
		z-index: 2;
		& > * {
			border-radius: 50%;
		}
		& > :nth-child(1) {
			position: absolute;
			top: -8px;
			left: -8px;
			z-index: 1;
		}
		& > :nth-child(2) {
			position: relative;
			z-index: 2;
		}
	}

	& > :nth-child(2) {
		& > :nth-child(1) {
			font-weight: 600;
			font-size: 14px;
			color: #1b191d;
		}
		& > :nth-child(3) {
			font-weight: 400;
			font-size: 14px;
			color: #939393;
		}
	}
}

.mobilePairName {
	& > :nth-child(1) {
		font-weight: 600;
		font-size: 14px;
		color: #1b191d;
	}
	& > :nth-child(3) {
		font-weight: 400;
		font-size: 14px;
		color: #939393;
	}
}

.tableDetail {
	font-weight: 400;
	font-size: 16px;
	color: #424243;
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
}

.btnAction {
	padding: 8px 16px;
	height: 36px;
	background: #5757f7;
	border-radius: 36px;
	font-weight: 400;
	font-size: 14px;
	color: #fdfcff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	&:active {
		opacity: 0.8;
	}
	&:hover {
		opacity: 0.9;
	}
}

.navMenu {
	display: flex;
	align-items: center;
	gap: 50px;
	height: 40px;
	margin-left: 40px;
	& > * {
		font-weight: 600;
		font-size: 16px;
		color: #1b191d;

		width: max-content;
		&:hover {
			color: #1b191d;
			border-bottom: 1px solid black;
		}
	}
	@media only screen and (max-width: 1200px) {
		margin-left: 0px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}
}

.iconText {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: #939393;
}

.headerDropdownMenu {
	display: flex;
	align-items: center;
	gap: 10px;
	& > :first-child {
		width: 48px;
		height: 48px;
		border: 1px solid black;
		border-radius: 50%;
	}
	margin-right: 50px;
}

.authBtn {
	display: flex;
	align-items: center;
	gap: 16px;
	& > :first-child {
		width: 112px;
	}
	& > :nth-child(2) {
		width: 92px;
	}
}

.assetsListName {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	color: #1b191d;
	gap: 8px;
}
.copyContainer {
	display: flex;

	align-items: center;
}
.copyBtnContainer {
	// min-width: 120px;
	border: none;
	background: transparent;
	&:active {
		opacity: 0.5;
	}
	text-align: left;
}

.orderStatus {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
}

.tooltipDefault {
	font-size: 16px !important;
	font-weight: 400 !important;
	z-index: 999;
}

.rewardReceived {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: #2ebd56;
}

.rewardWaiting {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: #2563eb;
}

.actions {
	display: flex;
	gap: 8px;
}

.pnlBtn {
	height: 36px;
	padding: 8px 12px;
	background: #fefefe;
	border: 1px solid #1c1a1f;
	border-radius: 36px;
	font-weight: 400;
	font-size: 14px;
	&:active {
		opacity: 0.8;
	}
	&:hover {
		opacity: 0.9;
	}
}

.menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 200px;
		& > :last-child {
			border-top: 1px solid #000;
			width: 100%;
		}
	}
}

.header {
	border-bottom: 1px solid black;
	min-height: 80px;
	align-items: center;
	background: #fff;
}

.mobileMenu {
	padding-top: 40px;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 20px;
}

.unAuthMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	& > * {
		width: 100%;
	}
	& > :last-child {
		display: flex;
		align-items: center;
		justify-content: space-around;
		position: absolute;
		bottom: 32px;
	}
}

.authMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	& > * {
		width: 100%;
		font-weight: 600;
		font-size: 16px;
		color: #1b191d;
	}
	& > :last-child {
		display: flex;
		align-items: center;
		justify-content: space-around;
		position: absolute;
		bottom: 32px;
	}
}
