.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
	border-color: var(--border-color);
}

.border {
	&.border-primary {
		border-color: var(--primary-color) !important;
	}
	&.border-secondary {
		border-color: var(--secondary-color) !important;
	}
	&.border-success {
		border-color: $color-success !important;
	}
	&.border-danger {
		border-color: $color-danger !important;
	}
	&.border-warning {
		border-color: $color-warning !important;
	}
	&.border-info {
		border-color: $color-info !important;
	}
	&.border-dark {
		border-color: var(--color-900) !important;
	}
	&.border-white {
		border-color: var(--color-fff) !important;
	}
}

.container-xxl {
	max-width: 1560px;
}
.font-weight-bold {
	font-weight: bold;
}
.table > :not(caption) > * > * {
	padding: 0.8rem 0.8rem;
}
.zindex-fixed {
	z-index: $zindex-fixed;
}
.zindex-popover {
	z-index: $zindex-popover;
}
.zindex-modal {
	z-index: $zindex-modal;
}
.small-xs {
	font-size: 13px !important;
}

// .form-control
.bootstrap-tagsinput {
	width: 100%;
}
.form-label {
	font-weight: 600;
}

.form-control,
.form-select {
	border-color: var(--border-color);
	background-color: var(--card-color);
	color: var(--text-color);
	font-size: 16px;
	&::placeholder {
		color: var(--color-400);
	}
	&:focus {
		box-shadow: 0 0 10px rgba($dark, 0.15);
		background-color: var(--card-color);
		border-color: var(--primary-color);
		color: var(--text-color);
	}

	&.form-control-lg {
		min-height: calc(1.5em + 1.8rem + 2px);
	}
}

.input-group-text {
	border-color: var(--border-color);
	background-color: var(--color-200);
	color: var(--text-color);
}

.form-control-lg {
	@media only screen and (max-width: $break-small) {
		min-height: calc(1.5em + 1rem + 2px) !important;
	}
}

.badge-info {
	background: var(--primary-color);
}

// form Switches css
.form-switch {
	.form-check-input {
		&:checked {
			background-color: var(--primary-color);
			border-color: var(--border-color);
		}
	}
}
.btn-link {
	color: var(--text-color);
	&:hover {
		color: var(--secondary-color);
	}
}
.bg-white {
	color: var(--text-color);
}
//lineheight
.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
	line-height: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
	line-height: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important;
	line-height: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important;
	line-height: calc(1.275rem + 0.3vw) !important;
}
// bottom
.btn {
	font-size: $font-size;
	color: var(--text-color);
	&:hover {
		color: var(--text-color);
	}
	&.btn-primary {
		background-color: var(--primary-color);
		border-color: var(--primary-color);
		color: var(--white-color);
		&.active {
			background-color: var(--secondary-color);
			border-color: var(--secondary-color);
		}
	}

	&.btn-light-primary {
		background-color: rgba(var(--primary-rgb), 0.15);
		color: var(--primary-color);
		border-color: transparent;

		&:hover {
			background-color: var(--primary-color);
			color: $white;
		}
	}

	&.btn-light-secondary {
		background-color: rgba($color-secondary, 0.15);
		color: $color-secondary;
		border-color: transparent;

		&:hover {
			background-color: $color-secondary;
			color: $white;
		}
	}

	&.btn-light-success {
		background-color: rgba($color-success, 0.15);
		color: $color-success;
		border-color: transparent;

		&:hover {
			background-color: $color-success;
			color: $white;
		}
	}

	&.btn-light-danger {
		background-color: rgba($color-danger, 0.15);
		color: $color-danger;
		border-color: transparent;

		&:hover {
			background-color: $color-danger;
			color: $white;
		}
	}

	&.btn-light-warning {
		background-color: rgba($color-warning, 0.15);
		color: $color-warning;
		border-color: transparent;

		&:hover {
			background-color: $color-warning;
			color: $white;
		}
	}

	&.btn-light-info {
		background-color: rgba($color-info, 0.15);
		color: $color-info;
		border-color: transparent;

		&:hover {
			background-color: $color-info;
			color: $white;
		}
	}

	&.btn-outline-primary {
		color: var(--primary-color);
		border-color: var(--primary-color);
		&:hover {
			color: $white;
			background-color: var(--primary-color);
		}
		&.active {
			background-color: var(--primary-color);
			color: var(--white-color);
		}
	}
	&.btn-outline-secondary {
		border-color: var(--border-color);
		&:hover {
			color: $white;
			background-color: var(--secondary-color);
			i {
				color: $white !important;
			}
		}
	}
	&.btn-outline-success {
		color: $color-success;
		border-color: $color-success;
		&:hover {
			background-color: $color-success;
			color: $white;
		}
	}
	&.btn-outline-danger {
		color: $color-danger;
		border-color: $color-danger;
		&:hover {
			background-color: $color-danger;
			color: $white;
		}
	}
	&.btn-outline-info {
		color: $color-info;
		border-color: $color-info;
		&:hover {
			background-color: $color-info;
			color: $white;
		}
	}
	&.btn-dark {
		color: var(--white-color);
	}
	&.btn-secondary {
		color: var(--white-color);
	}
	&.btn-lg {
		padding: 0.7rem 1rem;
	}
}
.btn-close {
	color: var(--text-color);
}
// avatar
.avatar {
	width: 40px;
	min-width: 40px;
	height: 40px;

	&.xs {
		width: 16px;
		min-width: 16px;
		height: 16px;
	}
	&.sm {
		width: 22px !important;
		min-width: 22px !important;
		height: 22px !important;
		i {
			font-size: 12px;
			line-height: 12px;
			vertical-align: text-top;
		}
	}
	&.lg {
		width: 45px;
		min-width: 45px;
		height: 45px;
	}
	&.xl {
		width: 80px;
		min-width: 80px;
		height: 80px;
	}
	&.xxl {
		width: 120px;
		min-width: 120px;
		height: 120px;
	}
	&.no-thumbnail {
		background-color: rgba(var(--primary-rgb), 0.15);
		color: var(--primary-color);
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.min-width-55 {
	min-width: 55px;
}
// dropdown menu
.dropdown-item:hover,
.dropdown-item:focus {
	background-color: rgba($dark, 0.1);
}
.dropdown-item {
	color: var(--text-color);
	&:hover {
		color: var(--secondary-color);
	}
}
.dropdown-menu {
	font-size: $font-size + 1px;
	color: var(--text-color);
	// background-color: var(--body-color);
	background: transparent;
	border: none;
	li {
		margin: 5px 0px;
	}
	.list-dropdown {
		margin-top: 8px;
		border-radius: 0px;
		border-top: 1px solid #000;
	}

	.dropdown-item {
		padding: 8px 24px;
		color: var(--text-color);
		font-size: 16px;
		font-weight: 600;

		// &.active {
		// 	color: var(--secondary-color);
		// 	background-color: var(--primary-color);
		// }
		// &:active,
		// &:focus {
		// 	background-color: var(--primary-color);
		// 	color: var(--secondary-color);
		// }
		&:hover {
			color: #fff;
			background-color: #5757f7;
		}
	}
}

// custom modal popup css
.modal {
	right: 0;
	left: auto;
	z-index: 99999;
	overflow: hidden;
	.modal-dialog {
		min-width: fit-content;
		height: 100%;
		display: flex;
		align-items: center;
	}

	&.fade {
		.modal-dialog-vertical {
			transform: translateX(-100%);
		}
	}
	&.show {
		.modal-dialog-vertical {
			transform: translateX(0);
		}
	}
	.modal-dialog-vertical {
		margin: 0;
		height: 100%;
		.modal-content {
			height: 100%;
		}
	}
	.modal-header,
	.modal-footer {
		border-color: var(--border-color);
	}
	.modal-content {
		// background-color: var(--body-color);
		padding: 32px;
		border: 1px solid #1c1a1f;
		box-shadow: 7px 7px 0px rgba(16, 6, 36, 0.7),
			0px 0px 2px rgba(16, 6, 36, 0.7);
		border-radius: 20px;
		min-width: max-content;
	}
	.modal-header {
		padding: 0px;
		padding-bottom: 12px;
		border-bottom: 1px solid #5757f6;
	}

	.modal-title {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
	}

	.modal-body {
		padding: 0px;
		margin-top: 24px;
	}
}

.form-check-input:checked {
	background-color: #5757f7 !important;
	border-color: #5757f7 !important;
}

// .form-check-input {
// 	border-color: #000;
// }

.modal {
	&.right {
		z-index: 99999;
		margin-top: 25px;
		margin-bottom: 25px;
		@media only screen and (max-width: 1279px) {
			margin-top: 0px;
			margin-bottom: 0px;
		}
		.modal-dialog {
			height: calc(100vh - 50px) !important;
			position: fixed;
			margin: auto;
			width: 320px;
			height: 100%;
			-webkit-transform: translate3d(0%, 0, 0);
			-ms-transform: translate3d(0%, 0, 0);
			-o-transform: translate3d(0%, 0, 0);
			transform: translate3d(0%, 0, 0);
			@media only screen and (max-width: 1279px) {
				height: 100vh !important;
			}
			@media only screen and (max-width: 767px) {
				height: 100vh !important;
			}
		}
		.modal-content {
			height: 100%;
			border-radius: 1.1rem !important;
			@media only screen and (max-width: 1279px) {
				border-radius: 0px !important;
			}
			.setting-theme,
			.dynamic-block,
			.setting-font,
			.sidebar-gradient {
				border-bottom: 1px solid #e2e2e2;
			}
			.setting-font,
			.setting-mode,
			.dynamic-block {
				.list-group .list-group-item {
					background-color: transparent;
					border-color: transparent;
					padding: 0px !important;
					margin-bottom: 5px;
				}
			}
			.font_setting {
				.form-check-input:checked {
					background-color: var(--secondary-color);
					border-color: var(--secondary-color);
					background-image: inherit;
				}
			}
			.setting-mode {
				.form-check-input:checked {
					background-color: var(--secondary-color);
					border-color: var(--secondary-color);
				}
			}
			.dynamic-block {
				.list-group-item {
					.avatar {
						&.xs {
							width: 25px;
							height: 25px;
							min-width: 25px;
							border-radius: 5px !important;
						}
					}
				}
			}
			.modal-footer {
				@media only screen and (max-width: 767px) {
					display: none;
				}
			}
		}
		.modal-body {
			@include overflow(scroll);
			overflow-y: auto;
		}
		&.fade {
			.modal-dialog {
				right: -320px;
				-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
				-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
				-o-transition: opacity 0.3s linear, right 0.3s ease-out;
				transition: opacity 0.3s linear, right 0.3s ease-out;
			}
			&.show {
				.modal-dialog {
					right: 10px;
					@media only screen and (max-width: 1279px) {
						right: 0px;
					}
				}
			}
		}
	}
}

// breadcrumb
.breadcrumb {
	background-color: var(--color-200);
}

// navbar
.navbar-light {
	.navbar-nav {
		.nav-link {
			color: var(--color-500);
			&:hover,
			&:focus {
				color: var(--primary-color);
			}
		}
	}
}

// custom tab ui
.nav-tabs {
	border-color: var(--border-color);
	&.tab-custom {
		border: 1px solid rgba($white, 0.2);
		.nav-link {
			color: $white;
			opacity: 0.7;
			&.active {
				opacity: 1;
				color: var(--primary-color);
			}
		}
	}
	&.tab-card {
		.nav-link {
			border-top: 0;
			border-left: 0;
			border-right: 0;
			background-color: transparent;
			border-bottom: 3px solid transparent;
			color: var(--color-500);

			&.active {
				border-color: var(--primary-color);
			}
		}
	}
	&.tab-body-header {
		border: 1px solid Var(--primary-color);
		overflow: hidden;
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
		.nav-item {
			@media only screen and (max-width: 767px) {
				width: 100%;
			}
			.nav-link {
				border: 0;
				margin: 4px;
				border-radius: 0;
				color: var(--color-500);

				&:hover {
					color: var(--primary-color);
				}

				&.active {
					background-color: var(--primary-color);
					color: $white;
				}

				&.buy {
					&.active {
						background-color: var(--buy-color);
						color: $white;
					}
				}

				&.sell {
					&.active {
						background-color: var(--sell-color);
						color: $white;
					}
				}
			}
		}
	}
}

// nav pill
.nav-pills {
	.nav-link.active,
	.show > .nav-link {
		background-color: var(--primary-color);
	}
	.nav-link {
		color: var(--text-color);
	}
}

//border
.border-dark {
	border-color: var(--text-color) !important;
}
.border-start {
	border-color: var(--border-color) !important;
}

// list-group design
.list-group-custom,
.list-group {
	.list-group-item {
		background-color: var(--card-color);
		border-color: var(--border-color);
		color: var(--text-color);
		&.active {
			background-color: var(--primary-color);
		}
	}
}

// .progress bar design
.progress {
	background-color: var(--color-100);
	&.plan-active {
		background-color: var(--color-300);
	}
}

// table
.table {
	border-color: var(--border-color);
	tr {
		th {
			//border-color: var(--primary-color);
			color: var(--color-800);
			text-transform: uppercase;
			font-size: 12px;
		}
		td {
			border-color: var(--border-color);
			color: var(--color-500);
			padding: 0.8rem 0.6rem;
		}
		&:hover {
			td {
				color: var(--color-900);
			}
		}
	}
	&.border-primary {
		tr th,
		tr td {
			border-color: var(--primary-color);
		}
	}
	&.table-sm {
		td {
			padding: 0.5rem 0.6rem;
		}
	}
	&.custom-table {
		border-collapse: separate !important;
		border-spacing: 0 5px !important;

		thead {
			tr {
				th {
					border: 0;
				}
			}
		}
		tbody {
			tr {
				background: var(--card-color);
				&.cancle-row {
					td {
						//background: rgba($color-danger, .05);
					}
				}
			}
		}
		tr {
			td,
			th {
				vertical-align: middle;
				white-space: nowrap;
				padding-left: 1rem;
				padding-right: 1rem;
				border-right: 0;
				border-left: 1px solid var(--border-color);

				&:first-child {
					border-radius: 0.25rem 0 0 0.25rem;
					border-left: 0;
				}
				&:last-child {
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
	&.custom-table-2 {
		border-collapse: separate !important;
		border-spacing: 0 1px !important;

		thead {
			tr {
				th {
					border: 0;
				}
			}
		}
		tbody {
			tr {
				background: var(--card-color);
				&.cancle-row {
					td {
						background: rgba($color-danger, 0.05);
					}
				}
				&.active-row {
					td {
						background: rgba($color-info, 0.05);
					}
				}
			}
		}
		tr {
			td,
			th {
				vertical-align: middle;
				white-space: nowrap;
				padding-left: 1rem;
				padding-right: 1rem;
				border-right: 0;

				&:first-child {
					border-radius: 0.25rem 0 0 0.25rem;
					border-left: 0;
				}
				&:last-child {
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
	&.accordion {
		border-collapse: separate !important;
		border-spacing: 0 1px !important;

		thead {
			tr {
				th {
					border: 0;
				}
			}
		}
		tbody {
			tr {
				background: var(--card-color);
				&.cancle-row {
					td {
						background: rgba($color-danger, 0.05);
					}
				}
				&.active-row {
					td {
						background: rgba($color-info, 0.05);
					}
				}
			}
		}
		tr {
			td,
			th {
				padding-left: 1rem;
				padding-right: 1rem;
				border-right: 0;

				&:first-child {
					border-radius: 0.25rem 0 0 0.25rem;
					border-left: 0;
				}
				&:last-child {
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
}

// pagination
.page-item {
	&.disabled {
		.page-link {
			background-color: var(--border-color);
			border-color: var(--border-color);
		}
	}
	.page-link {
		background-color: var(--card-color);
		border-color: var(--border-color);
		color: var(--primary-color);
	}
	&.active {
		.page-link {
			background-color: var(--primary-color);
			border-color: var(--primary-color);
			color: var(--white-color);
		}
	}
}

//Side navbar
.side-navbar {
	li {
		margin-bottom: 5px;
		a {
			color: var(--text-color);
		}
	}
}

//button
.btn-outline-secondary.btn-block {
	color: #fff !important;
}

//navbar
.navbar-toggler:focus {
	box-shadow: none;
}

//Date & Time
input[type="date"],
input[type="time"] {
	display: block;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	min-width: 95%;
}

//tageinput
.bootstrap-tagsinput,
.dropify-wrapper {
	border: 1px solid var(--border-color);
	background-color: var(--card-color);
}
.logoutBtn {
	cursor: pointer;
}
.mr-left {
	margin-left: 10px;
	position: absolute;
	right: -23px;
	top: -3px;
}
.relative {
	position: relative;
}
.tableCustom {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	min-height: 0;
}
.custom-layout-spot {
	@media (min-width: 768px) {
		.col-md-2 {
			flex: 0 0 auto;
			width: 21%;
		}
		.col-md-10 {
			flex: 0 0 auto;
			width: 79%;
		}
	}
	#priceTableup_wrapper {
		span,
		div {
			font-size: 12px;
		}
	}
	.tableCustom {
		.rdt_Table {
			max-width: 280px;
		}
		.rdt_TableCol {
			padding: 0px;
			max-width: 33%;
			min-width: 0px !important;
		}
		.cxcOiE {
			min-height: 30px;
		}
		.rdt_TableHeadRow {
			min-height: 36px;
			> div:last-child {
				justify-content: flex-end;
			}
		}
		.rdt_TableRow {
			border: none !important;
			cursor: pointer;
			position: relative;
			min-height: 30px;
			> div:last-child {
				justify-content: flex-end;
			}
		}
		.rdt_TableCell {
			padding: 7px 0px;
			vertical-align: middle;
			// min-width: 60px;
			max-width: 93px !important;
			min-width: 0px !important;
			// max-width: 30%;
			overflow: clip;
			white-space: nowrap;
		}
	}
	.orderbook-ticker {
		display: flex;
		padding: 15px 0px 5px 0;
		-webkit-box-align: center;
		align-items: center;
		.contractPrice {
			display: flex;
			margin-right: 4px;
			font-size: 20px;
			-webkit-box-align: center;
			align-items: center;
			font-weight: bold;
		}

		.markPrice {
			flex: 1 1 0%;
			display: inline-block;
			font-size: 12px;
			text-align: left;
			color: rgb(132, 142, 156);
			padding-top: 6px;
			margin-left: 10px;
		}
		.more {
			font-size: 12px;
			color: rgb(132, 142, 156);
			text-decoration: none;
		}
	}
}

.fill-bid {
	background: linear-gradient(to left, #99ffcc 100%, white 0%);
	position: absolute;
	width: 100%;
	height: 28px;
	right: 0;
	top: 0;
	opacity: 0.5;
	z-index: 0;
}
.z-top {
	z-index: 9;
	position: relative;
}

.fill-ask {
	background: linear-gradient(to left, #f148484d 100%, white 0%);
	position: absolute;
	width: 100%;
	height: 28px;
	right: 0;
	top: 0;
	opacity: 0.5;
	z-index: 0;
}

#priceTableup_wrapper,
#priceTabledown_wrapper {
	height: 368px;
}

.color-ask {
	color: rgb(246, 70, 93) !important;
}

.color-bids {
	color: rgb(0, 192, 0) !important;
}
.orderSpot .input-group input {
	text-align: right;
}
.css-i54jlg {
	min-width: 0px;
	display: flex;
	box-sizing: border-box;
	margin: 0px;
	flex: 1 1 0%;
}
.css-i54jlg .left {
	display: flex;
	box-sizing: border-box;
}
.css-i54jlg .right {
	flex: 1 1 0%;
	flex-flow: row nowrap;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	-webkit-box-pack: justify;
	justify-content: space-between;
	min-width: 0px;
	overflow: hidden;
}
.css-i54jlg .left .layout {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	margin-right: 0px;
	flex: 0 0 auto;
}
.css-i54jlg .left .layout .childrenContainer {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	flex: 1 1 0%;
}
.css-l36dyj {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	flex-direction: column;
	// padding-right: 24px;
	height: auto;
	justify-content: space-around;
	border-right: 0px solid rgb(37, 41, 48);
	width: 16.25vw;
}
.css-4h6mys {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	height: 60px;
	flex-wrap: nowrap;
}
.css-1qkv3vk {
	box-sizing: border-box;
	margin: 0px 4px 0px 0px;
	min-width: 0px;
	color: #000000;
	padding: 15px 0px 15px 30px;
	border-right: 1px solid #ebebeb;
	width: 16.25vw;
}
.css-1qkv3vk h2 {
	font-size: 20px;
	font-weight: 500;
	margin: 0px;
	padding: 0px;
	color: #000000;
}
.css-i54jlg .left .nowPrice {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	margin-left: 20px;
	width: 90px;
}
.css-i54jlg .left .nowPrice .showPrice {
	font-size: 16px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	color: #000000;
	font-weight: bold;
}
.css-i54jlg .left .nowPrice .subPrice {
	font-size: 12px;
	color: #000000;
	line-height: inherit;
}
.css-i54jlg .right {
	flex: 1 1 0%;
	flex-flow: row nowrap;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	-webkit-box-pack: justify;
	justify-content: space-between;
	min-width: 0px;
	overflow: hidden;
}
.css-i54jlg .right .tickerListContainer {
	display: flex;
	flex-flow: row nowrap;
	box-sizing: border-box;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	margin-left: 32px;
	margin-right: 8px;
	overflow: hidden;
}
.css-i54jlg .right .tickerListContainer .tickerList {
	white-space: nowrap;
	overflow: auto hidden;
}
.css-i54jlg .right .tickerListContainer .tickerList > div {
	display: inline-block;
	padding-right: 32px;
}
.css-i54jlg .right .tickerListContainer .tickerList .tickerItemLabel {
	font-size: 12px;
	margin-right: 0px;
	margin-bottom: 2px;
	color: rgb(132, 142, 156);
	line-height: 16px;
	font-weight: 400;
	text-align: left;
	border-bottom: 1px solid transparent;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.css-i54jlg .right .tickerListContainer .tickerList .tickerPriceText {
	font-size: 12px;
	color: #000000;
}
.css-i54jlg .right .tickerListContainer .tickerList .tickerPriceText span div {
	float: left;
}

.searchToken .title {
	float: right;
	margin-right: 10px;
}
.searchToken .buttonSearch {
	border-radius: 5px;
	background: #feb019;
	color: #fff !important;
	padding: 5px 15px 5px 15px;
	cursor: pointer;
}

.showTokenSpot {
	.dropdownTokenSpot {
		position: absolute;
		right: 0;
	}
	ul {
		margin: 10px 0 0 0 !important;
		li {
			&.col {
				cursor: pointer;
				margin-top: 0;
				margin-bottom: 20px;
			}
		}
	}
}

input[type="search"] {
	min-width: 250px;
}

.btnLight {
	height: 40px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 22px;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	color: #000;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
	color: #363638;
	&:disabled {
		cursor: not-allowed;
		background-color: #939393;
	}
}

.btnLight:not([disabled]):active {
	box-shadow: rgba(16, 6, 36, 0.6) 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.btnRound {
	height: 72px;
	width: 72px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 50%;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
	color: #363638;
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 1200px) {
		height: 48px;
		width: 48px;
	}
}

.btnRound:active {
	box-shadow: rgba(16, 6, 36, 0.6) 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.btnRound:hover {
	background-color: #5757f7;
	color: #fdfcff;
}

.btnRoundSmall {
	height: 48px;
	width: 48px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 50%;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
	color: #363638;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnRoundSmall:active {
	box-shadow: rgba(16, 6, 36, 0.6) 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.btnRoundSmall:hover {
	background-color: #5757f7;
	color: #fdfcff;
}

.btnDisabled {
	height: 40px;
	width: 100%;
	background: #dedde0;
	border: 1px solid #000;
	border-radius: 22px;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	color: #000;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
	color: #6f6f6f;
	cursor: not-allowed;
}

.contentContainer {
	box-sizing: border-box;
	gap: 10px;
	background: #ffffff;
	border-radius: 20px;
	border: 1px solid rgba(3, 1, 6, 0.9);
	box-shadow: 7px 7px 0px rgba(16, 6, 36, 0.7),
		0px 0px 2px rgba(16, 6, 36, 0.7);
	// width: fit-content;
	// height: fit-content;
	position: relative;
	overflow: hidden;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		background: #ffffff;
		box-shadow: 4px 4px 0px rgba(16, 6, 36, 0.6),
			0px 0px 1px rgba(16, 6, 36, 0.5);
		border-radius: 20px;
		border: 1px solid #1c1a1f;
		position: relative;
		overflow: hidden;
	}
}

.btnDark {
	width: 100%;
	height: 40px;
	appearance: button;
	background-image: none;
	border: 1px solid #000;
	background: #1b191d;
	border-radius: 22px;
	box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 8px;
	text-align: center;
	text-transform: none;
	touch-action: manipulation;
	user-select: none;
	-webkit-user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	color: #fdfcff;
	font-weight: 600;
	font-size: 16px;
	&:disabled {
		cursor: not-allowed;
		background-color: #939393;
	}
}

.btnDark:not([disabled]):focus {
	text-decoration: none;
}

.btnDark:not([disabled]):hover {
	text-decoration: none;
	background: #5757f7;
}

.btnDark:not([disabled]):active {
	box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
	outline: 0;
}

.btnDark:not([disabled]):active {
	box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
	transform: translate(2px, 2px);
}

.text-error {
	color: rgba(221, 76, 86, 1);
}

.nav-tabs {
	border: none;
	& > * {
		border: 0px;
	}
	@media only screen and (max-width: 1200px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.nav-item {
	border: none;
	@media only screen and (max-width: 1200px) {
		width: 40vw;
		& > :first-child {
			width: 100%;
		}
	}
}

.nav-tabs .nav-link.active {
	border: none;
	font-weight: 600;
	font-size: 16px;
	color: #fdfcff;
	background: #363638;
	border-radius: 20px;
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
}

.nav-tabs .nav-link:hover {
	border: none;
}

.nav-tabs .nav-link {
	border: none;
	font-weight: 600;
	font-size: 16px;
	color: #939393;
	border-radius: 20px;
	height: 40px;
	padding: 8px 16px;
	margin-right: 40px;
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
		margin-right: 20px;
		height: fit-content;
	}
}

.priceUp {
	color: #2ebd56;
}

.priceDown {
	color: #dd4c56;
}

.info {
	color: #2563eb;
}

.marketTable {
	.rdt_TableCell {
		padding: 0.8rem 0rem 0.8rem 0.8rem;
		font-weight: 400;
		font-size: 16px;
		color: #424243;
		min-height: 66px;
	}
	.rdt_TableHeadRow {
		.rdt_TableCol {
			padding: 0;
			// height: min-content;
			padding-bottom: 12px;
			font-size: 16px;
			font-weight: 600;
		}
		border-bottom: 1px solid #363638;
	}
	.rdt_TableRow {
		border: none !important;
		cursor: pointer;
	}

	@media only screen and (max-width: 1200px) {
		.rdt_TableCell {
			padding: 1rem 0rem;
			font-weight: 400;
			font-size: 14px;
			max-width: 33%;
			width: 33%;
		}
		.rdt_TableHeadRow {
			.rdt_TableCol {
				padding: 0;

				padding-bottom: 12px;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}

.pnlTable {
	.rdt_TableCell {
		font-weight: 400;
		font-size: 16px;
		color: #424243;
		height: 60px;
	}
	.rdt_TableHeadRow {
		.rdt_TableCol {
			font-size: 16px;
			font-weight: 600;
		}
		border-bottom: 1px solid #363638;
	}
	.rdt_TableRow {
		border: none !important;
		cursor: pointer;
	}
}

.historyTable {
	.rdt_TableCell {
		padding: 0.8rem 0rem 0.8rem 0rem;
		font-weight: 400;
		font-size: 16px;
		color: #424243;
	}
	.rdt_TableHeadRow {
		.rdt_TableCol {
			padding: 0;
			height: min-content;
			padding-bottom: 12px;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.iSAVrt {
		min-height: 0px;
		border-bottom: 1px solid #363638;
	}
	.rdt_TableRow {
		border: none !important;
	}
}

.depthTable {
	.rdt_TableCell {
		padding: 0px;
		height: 20px;
		color: #424243;
		min-width: 80px;
		max-width: 80px;
	}
	.rdt_TableHeadRow {
		height: min-content;
		min-height: 0px;
		.rdt_TableCol {
			padding: 0;
			height: min-content;
			font-weight: 400;
			font-size: 14px;
			color: #6f6f6f;
			line-height: 22px;
			min-width: 80px;
			max-width: 80px;
		}
	}
	.iSAVrt {
		min-height: 0px;
		border: none;
	}
	.rdt_TableRow {
		border: none !important;
		height: min-content;
		min-height: 0px;
		overflow: hidden;
	}
}

.inputContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	padding: 8px 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.inputContainerShadow {
	box-shadow: 4px 4px 0px rgba(16, 6, 36, 0.7),
		0px 0px 2px rgba(16, 6, 36, 0.7);
}

.customInput {
	outline: none;
	padding: 0px;
	margin: 0px;
	border: none;
	width: 100%;
	font-size: 14px;
}

.customInput:focus {
	outline: none;
}

.customInput::placeholder {
	color: rgba(147, 147, 147, 1);
}

.contentContainerMedium {
	background: #ffffff;
	box-shadow: 4px 4px 0px rgba(16, 6, 36, 0.6),
		0px 0px 1px rgba(16, 6, 36, 0.5);
	border-radius: 20px;
	border: 1px solid #1c1a1f;
	position: relative;
	overflow: hidden;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.contentContainerMediumFixed {
	background: #ffffff;
	box-shadow: 4px 4px 0px rgba(16, 6, 36, 0.6),
		0px 0px 1px rgba(16, 6, 36, 0.5);
	border-radius: 20px;
	border: 1px solid #1c1a1f;
	position: relative;
	overflow: hidden;
	width: fit-content;
}

.heading5 {
	font-weight: 600;
	font-size: 16px;
	color: #424243;
}

.defaultContainer {
	width: 1176px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.walletLayout {
	padding-top: 48px;
	padding-bottom: 48px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;

	flex-direction: column;
	& > :last-child {
		margin-top: 56px;
	}

	@media only screen and (max-width: 1200px) {
		padding: 16px;
		padding-bottom: 100px;
	}
}

.contentHead {
	width: 100%;
	padding: 14px 28px;
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
	border-bottom: 1px solid #1c1a1f;
	@media only screen and (max-width: 1200px) {
		padding: 12px;
	}
}

.contentBody {
	padding: 20px 28px;
	@media only screen and (max-width: 1200px) {
		padding: 20px 12px;
	}
}
.buyBadge {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.5px solid #2ebd56;
	background: #d5f2dd;
	border-radius: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #2ebd56;
}

.sellBadge {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.5px solid #dd4c56;
	background: #fbe5e7;
	border-radius: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #dd4c56;
}

.primaryBtnSmall {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 4px 12px;
	height: 28px;
	background: #5757f7;
	border-radius: 14px;
	font-weight: 400;
	font-size: 14px;
	color: #fdfcff;
	border: none;
	&:hover {
		opacity: 0.8;
	}
	&:active {
		opacity: 0.6;
	}
}

.primaryBtn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 4px;
	background: #5757f7;
	border-radius: 14px;
	font-weight: 400;
	font-size: 14px;
	color: #fdfcff;
	border: none;
	&:hover {
		opacity: 0.8;
	}
	&:active {
		opacity: 0.6;
	}
}

.binance-widget-custom {
	height: 50px;
	overflow: hidden;
	& > * {
		pointer-events: none;
	}
}

.not-optimized {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	text-align: center;
}
.stickyBadge {
	position: sticky;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	pointer-events: none;
	& > * {
		pointer-events: auto;
	}
}

.codeInput {
	width: 45px;
	height: 45px;
	padding: 0;
	font-size: 24px;
	text-align: center;
	margin-right: 12px;
	text-transform: uppercase;
	color: #494949;
	border: 1px solid #000;
	border-radius: 4px;
	background: #fff;
	background-clip: padding-box;
}

.codeInput:focus {
	appearance: none;
	outline: 0;
}

.loadingCenter {
	width: 100%;
	margin: auto;
	margin-top: 40px;
	text-align: center;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
	color: white;
	opacity: 1;
	background-color: #bcbcfb !important;
}

.navbar-toggler {
	border: none !important;
}
.offcanvas.offcanvas-end {
	border: none !important;
	width: 100vw;
	// overflow-x: hidden;
}

.only-mobile {
	@media only screen and (min-width: 1200px) {
		display: none;
	}
}

.only-desktop {
	@media only screen and (max-width: 1200px) {
		display: none;
	}
}

.mobileModal {
	overflow: hidden;
	display: flex !important;
	align-items: flex-end;
	padding: 0px !important;
	.mobileModal-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		color: #1b191d;
		padding: 16px;
		max-width: 100vw;
	}
	.modal-dialog {
		width: 100%;
		padding: 0px;
		height: fit-content !important;
		margin: 0px;
	}
	.modal-content {
		box-shadow: none;
		border-radius: 20px 20px 0px 0px;
		padding: 0px !important;
		overflow: hidden;
	}
	.mobileModalBody {
		max-height: 80vh;
		overflow-y: scroll;
		padding: 16px;
		padding-bottom: 0px;
		max-width: 100vw;
		position: relative;
	}
}

.Toastify__toast-container {
	z-index: 999999 !important;
	// border-radius: 5px;
}

.Toastify__toast {
	border-radius: 5px !important;
	box-shadow: 4px 4px 0px rgba(16, 6, 36, 0.6),
		0px 0px 1px rgba(16, 6, 36, 0.5) !important;
}
