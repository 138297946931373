.dashboardContainer {
	width: clamp(300px, 100%, 1176px);
	margin: auto;
	padding-top: 40px;
	padding-bottom: 40px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 56px;
	@media only screen and (max-width: 1200px) {
		padding-left: 16px;
		padding-right: 16px;
	}
}

.heading {
	font-weight: 600;
	font-size: 24px;
	color: #1b191d;
	margin-bottom: 20px;
}

.dashboardContent {
	width: clamp(300px, 100%, 1176px);
	padding: 32px 100px 0px 100px;
	display: flex;
	justify-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 20px 12px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

.asset {
	& > :nth-child(1) {
		font-weight: 600;
		font-size: 20px;
		color: #424243;
		margin-bottom: 0;
	}
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 48px;
		color: #1b191d;
		margin-bottom: 0;
	}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;

	@media only screen and (max-width: 1200px) {
		& > :nth-child(1) {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
		& > :nth-child(2) {
			font-weight: 600;
			font-size: 24px;
		}
	}
}
.dashboardButtonContainer {
	display: flex;

	flex: 2;
	margin-left: 48px;
	justify-content: space-between;
	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}

	@media only screen and (max-width: 1200px) {
		margin-left: 0px;
		& > * {
			// width: 33%;
		}
	}
}

.dashboardBtnLabel {
	font-weight: 400;
	font-size: 20px;
	color: #939393;
	display: flex;
	align-items: center;
	gap: 10px;

	@media only screen and (max-width: 1200px) {
		font-size: 14px;
		gap: 4px;
	}
}

.trendingContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 1200px) {
		gap: 20px;
		overflow-x: scroll;
	}
}
.dashboardChart {
	border-radius: 10px;
	border: 1px solid #1b191d;
	padding-top: 28px;
	overflow: hidden;
	@media only screen and (max-width: 1200px) {
		display: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.symbol {
	color: #1b191d;
}

.volume {
	color: #6f6f6f;
}

.priceDetailContainer {
	@media only screen and (max-width: 1200px) {
		width: 170px;
	}
}

.trendingDetailFirstLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
	margin-top: 8px;
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
}

.trendingDetailSecondLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-weight: 400;
	font-size: 12px;
}

.priceUp {
	color: #2ebd56;
}

.priceDown {
	color: #dd4c56;
}

.trendingItem {
	cursor: pointer;
}

.changeContent {
	width: 1176px;
	padding: 24px;
	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		padding: 16px;
	}
}
.tabTitle {
	display: flex;
	align-items: center;
	gap: 8px;
}
.changeGrid {
	margin-top: 20px;
	display: grid;
	gap: 16px 100px;
	grid-auto-flow: column;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(5, auto);
}

.changeBox {
	margin-top: 20px;
	display: flex;
	gap: 16px;
	flex-direction: column;
	& > * {
		padding: 10px 20px;
		background: #fdfcff;
		color: #424243;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		border: 1px solid #6f6f6f;
		& > :first-child {
			display: flex;
			gap: 8px;
			align-items: center;
			& > :first-child {
				font-weight: 400;
				font-size: 14px;
			}

			& > :nth-child(3) {
				font-weight: 600;
				font-size: 14px;
			}
		}
		& > :nth-child(2) {
			display: flex;
			align-items: center;
			& > :first-child {
				font-weight: 600;
				font-size: 14px;
				color: #424243;
				width: 10px;
				direction: rtl;
			}

			& > :nth-child(2) {
				margin-left: 20px;
			}
		}

		&:hover {
			background: #8989f9;
			color: #fdfcff;
			& > :first-child {
				& > :nth-child(4) {
					color: #fdfcff;
				}
			}
			& > :nth-child(2) {
				& > :first-child {
					color: #fdfcff;
				}
			}
		}
	}
}

.gridItem {
	padding: 10px 20px;
	background: #fdfcff;
	color: #424243;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border: 1px solid #6f6f6f;

	& > :first-child {
		display: flex;
		gap: 10px;
		align-items: center;
		& > :first-child {
			min-width: 20px;
			font-weight: 400;
			font-size: 16px;
		}

		& > :nth-child(3) {
			font-weight: 600;
			font-size: 16px;
		}

		& > :nth-child(4) {
			color: #939393;
			width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	& > :nth-child(2) {
		display: flex;
		& > :first-child {
			font-weight: 600;
			font-size: 16px;
			color: #424243;
			width: 10px;
			direction: rtl;
		}

		& > :nth-child(2) {
			width: 70px;
			margin-left: 20px;
		}
	}

	&:hover {
		background: #8989f9;
		color: #fdfcff;
		& > :first-child {
			& > :nth-child(4) {
				color: #fdfcff;
			}
		}
		& > :nth-child(2) {
			& > :first-child {
				color: #fdfcff;
			}
		}
	}
	@media only screen and (max-width: 1200px) {
		max-width: 100%;
	}
}
.marketListContent {
	width: 1176px;
	padding: 24px;
	min-height: 738px;
	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		&::-webkit-scrollbar {
			display: none;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
}

.marketListContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.marketListHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	& > :nth-child(2) {
		margin-bottom: 0px;
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		& > :nth-child(2) {
			width: 100%;
		}
	}
}

.marketHead {
	display: flex;
	align-items: center;
	gap: 10px;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #1b191d;
	}
}

.searchMarket {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 376px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.pairTabs {
	& > * {
		& > :first-child {
			margin-right: 8px;
		}
		@media only screen and (max-width: 1200px) {
			width: fit-content;
			margin: 0px;
		}
	}
}

.pnlOverview {
	display: flex;
	align-items: center;
	gap: 20px;
	padding-left: 100px;
	padding-bottom: 32px;
	padding-top: 16px;
	font-size: 16px;

	& > * {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	& > :first-child {
		font-weight: 400;

		color: #6f6f6f;
		& > :nth-child(2) {
			font-weight: 600;
		}
	}

	& > :nth-child(2) {
		line-height: 28px;
		color: #5757f7;
		cursor: pointer;
	}
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
		padding: 0px;
		padding: 12px 20px;
	}
}

// .mobileTab {
// 	background-color: #2ebd56;
// 	& > :first-child {
// 		font-size: 14px;
// 		& > :first-child {
// 			margin-right: 20px;
// 		}
// 	}
// }
